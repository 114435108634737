@media (max-width: 766px) {
    .content-wrapper {
        background-color: #fff;
    }

    .dark-mode .content-wrapper {
        background-color: #343a40;
    }

    #contentDiv {
        padding: 0 !important;
    }

    .card-mobile {
        border: none;
        box-shadow: none;
    }

    .card-mobile > .card-body {
        padding: 16px 8px 8px 8px !important;
    }

    .section-mobile {
        padding: 8px !important;
    }
}