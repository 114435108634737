.checkbox-filter {
    webkit-appearance: "checkbox";
    zoom: 1.5;
    pointer-events: none
}

@media (max-width: 766px) {
    #dropdown-status, #status-filter {
        width: 100%;
    }
}