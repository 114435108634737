.documents-table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid var(--au-hr);
}

.deadline {
    font-size: 12px;
    color: var(--au-orange) !important;
    font-weight: 700;
    margin: 0;
}

.signed-in {
    font-size: 12px;
    color: var(--au-green) !important;
    font-weight: 700;
    margin: 0;
}

.not-signed-in {
    font-size: 12px;
    color: var(--au-red) !important;
    font-weight: 700;
    margin: 0;
}

.created-at {
    font-size: 12px;
    color: var(--au-text-color) !important;
    margin: 0;
}

.unread {
    font-weight: 700;
}

.number-of-signatures {
    font-size: 12px;
    margin: 0;
}

.documents-pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px !important;
}