.container-login {
  background-color: #343a40;
  min-height: 100vh;
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
}

.box-panel {
  background-color: white;
  border-radius: 4px;
  width: 400px;
  padding: 18px;
}

.botao-login {
  display: flex;
  justify-content: center;
  padding: auto 5%;
  margin-top: 17px;
}

.p-login {
  margin-top: 30px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .box-panel {
    width: 90%;
  }
}
