.cards-table {
    font-size: 12px;
}

.cards-table > tbody > tr > th {
    vertical-align: middle;
}

.current-invoice {
    text-align: end;
}

ul {
    list-style-type: none;
}