// Cores da aplicação
$laranja: #EC582A;

body:not(.layout-fixed) .main-sidebar {
  min-height: 100vh !important;
  height: 100vh !important;
  position: fixed;
}

.inputPassword {
  -webkit-text-security: disc;
}

.documento-item {
  padding: 16px 8px;
  border-bottom: 1px solid #DCE3E9;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.link-documento {
  color: #4d4d4f;
  text-decoration: none;
  word-break: break-word;
  padding-left: 8px;
  font-size: 16px;
}

.link-documento:hover {
  text-decoration: underline;
  color: #4d4d4f;
}

.nome-documento {
  display: flex;
  align-items: center;
  flex: 1;
}

.data-documento {
  font-size: 12px !important;
  font-weight: 400;
  color: #fff;
  border: 1px solid #6c757d;
  background-color: #6c757d;
}

.info-assinante > span {
  font-size: 16px;
}

#mainDiv {
  min-height: calc(100vh - 114px);
  height: auto;
}

// card
.card-body {
  padding: 20px !important;
}

.card-primary.card-outline-tabs>.card-header a.active {
  border-top-color: #F1581B !important;
}

.dark-mode .card-primary.card-outline {
  border-top: none;
}

.nav-tabs a {
  color: black !important;
}

.page-link {
  color: #666;
}

.page-link:hover {
  color: #666;
}

.page-item.active .page-link {
  background-color: #F1581B !important;
  border-color: #F1581B !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.page-item.disabled .page-link {
  background-color: #8888884a !important;
}

.nome-doc-escolhido {
  border-right: 1px solid #888;
  margin-right: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.doc-escolhido {
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0 !important;
  box-shadow: none !important;
}

.btn-cinza {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: none;
}

.btn-cinza:hover {
  color: #fff !important;
  text-decoration: none;
}

#div-confirmarEmail input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#div-confirmarEmail input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;

}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #f1581b !important;
}

.div-desativada {
  pointer-events: none;
  opacity: 0.7;
}

// active
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #F1581B !important;
}

// form
.form-inline .form-group {
  margin-right: 8px;
}

.form-control:focus {
  border-color: #F1581B !important;
}

.form-select:focus {
  border-color: #F1581B !important;
  box-shadow: none !important;
}

.input-error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

// btn
.btn-assinauai {
  color: #fff;
  background-color: #F1581B;
  border-color: #F1581B;
  box-shadow: none;
}

.btn-assinauai:hover {
  color: #fff;
}

.btn-link {
  text-decoration: none !important;
  color: #f1581b;
  padding: 0;
  margin: 0;
}

.btn-link:hover {
  color: #f1581b;
}

.btn-numpad {
  color: #fff;
  background-color: #F1581B;
  border-color: #F1581B;
  box-shadow: none;
  border-radius: 0;
}

.btn-numpad-empty {
  background-color: transparent;
  border-color: transparent;
}

@media (max-width: 766px) {
  .btn-sm-block {
    width: 100%;
  }
}

// switch
.switch {
  margin-bottom: 0 !important;
  margin-left: 5px;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

// badge
.badge-info {
  background-color: #039BE5 !important;
}

.badge-danger {
  background-color: #e53935 !important;
}

.badge-diretorio {
  padding: 5px 15px;
  border-radius: 15px;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
}

.badge-diretorio:hover {

}

// Navbar
.navbar-badge {
  font-size: .6rem;
  font-weight: 300;
  padding: 2px 4px;
  position: absolute;
  right: 0;
  top: 3px;
}

.notification {
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
  min-width: 298px;
}

.alert-light {
  color: black;
  background-color: transparent;
  border-color: #8888881f;
}

// alert
.alert-danger {
  background-color: #e53935 !important;
  border-color: #e53935 !important;
  font-size: 14px;
  color: white;
  padding: 8px;
}

// código de validação
.codigo-validacao {
  max-width: 100% !important;
  width: auto !important;
  justify-content: center;
  display: flex;
}

.codigo-validacao > div > input {
  outline: 0;
}

.codigo-validacao > div > input:focus {
  border: 1px solid #F1581B;
  caret-color: #141414;
}

// Informação
.span-info {
  font-size: 12px;
}

// Breadcrumb
.breadcrumb {
  background-color: transparent !important;
}

.breadcrumb-item > a {
  color: #F1581B !important;
}

// Pastas
.pasta {
  -webkit-user-select: none;
  border-radius: 3px;
  margin-bottom: 23px;
  text-align: center;
  border: 1px solid #dadce0;
  transition: 0.2s all cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  padding: 10px;
  margin-right: 17px;
  cursor: pointer;
}

.nome-pasta {
  font-weight:400;
  font-size:16px;
}

.desc-pasta {
  font-size: 12px;
  color: #888;
  margin: 0 !important;
}

.pasta.active {
  border: 1px solid #F1581B !important;
}

.pasta.active > i {
  color: #F1581B;
}

.pastas-header {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 766px) {
  .pastas-header {
    flex-direction: column;
    align-items: baseline;
  }

  .pastas-header > .add-pasta {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    order: 1;
  }

  .pastas-header > .pastas-tree {
    order: 2;
  }
}

// Sweetalaert
.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border-color: #F1581B !important;
  box-shadow: none !important;
}

// navbar assina uai
a.navbar-brand-assinauai.navbar-brand {
  padding-bottom: 0;
  font-size: 0;
  padding-top: 0 !important;
}

.navbar-assinauai {
  background-color: #F1581B !important;
  padding: 15px;
  border: 1px solid rgba(0,0,0,.125);
  box-shadow: 0 0 3px rgba(0,0,0,.2);
}

div.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.form-check-input:checked {
  background-color: #F1581B;
  border-color: #F1581B;
}

.form-check-input:focus {
  border-color: #f1581b96 !important;
}

.checkbox-au:checked:before {
  background-color: #F1581B;
  border-color: #F1581B;
}

.checkbox-au-input {
  margin: 0 0 0 8px;
  font-size: 14px;
  font-weight: 400 !important;
  -webkit-appearance: checkbox;
}

.checkbox-appearance {
  -webkit-appearance: checkbox;
}

// PROGRESS
// IONIC DEFAULT THEME COLORS
$colors: (
        primary:    #F1581B,
);

// https://css-tricks.com/html5-progress-element/
.progress, progress[value] {
  width:              100%;
  border:             none;
  margin:             5px 0;
  height:             5px;
  appearance:         none;
  -webkit-appearance: none;
  &::-webkit-progress-bar {
    background-color: lighten( map-get($colors, primary), 35% );
  }
  &::-webkit-progress-value {
    background-color: map-get($colors, primary);
  }
}

.progress-bar {
  background-color: #F1581B;
}

// http://materializecss.com/preloader.html
// https://github.com/Dogfalo/materialize/blob/master/dist/css/materialize.css
.progress-materializecss {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color:  lighten( map-get($colors, primary), 35% );
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
  .indeterminate {
    background-color: map-get($colors, primary);
    &:before{
      content:          '';
      position:         absolute;
      background-color: inherit;
      top:              0;
      left:             0;
      bottom:           0;
      will-change:      left, right;
      animation:        indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    &:after {
      content:          '';
      position:         absolute;
      background-color: inherit;
      top:              0;
      left:             0;
      bottom:           0;
      will-change:      left, right;
      animation:        indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

// DROPDOWN
.dropdown-item.active, .dropdown-item:active {
  background-color: #F1581B;
}

.dropdown-payment.show {
  position: fixed !important;
}

.nav-item > .nav-link.active > div {
  color: white !important;
}

[class*=sidebar-dark] .user-panel {
  border-bottom: 1px solid #f1581b;
}

.user-panel img {
  width: 2.5rem;
}

// CreatePasswod do consumo
.dados-consumo {
  text-align: end;
}

// Aviso
.aviso {
  padding: 8px;
  background-color: #ffc107;
  color: white;
  cursor: pointer;
}

// lista
.list-group-item.active {
  background-color: #f1581b;
  border-color: #f1581b;
}

// Seleção de contatos
.tabela-selecao-contatos > p {
  margin: 0
}

// Numpad
.numpad > button {
  height: 64px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: 4px;
  border-radius: 16px;
}

// Liveness
.liveness-help-ul {
  list-style-type: none;
  padding: 0;
}

.liveness-help-ul > li {
  margin-bottom: 1rem;
}

// Container
.container-center-au {
  background-color: #343a40;
  min-height: 100vh;
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

// Policy & terms
.policy-terms {
  padding-left: 0 !important;
}

.policy-terms li {
  list-style-type: none;
  margin-bottom: 12px;
}

// Table
td {
  vertical-align: middle !important;
}

// Datepicker
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: var(--au-orange);
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: var(--au-orange);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #f1581b9c;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: var(--au-orange);
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--au-orange);
}

.react-datepicker__close-icon::after {
  background-color: var(--au-orange) !important;
}

// INPUT
@media (max-width: 766px) {
  .input-full-sm {
    width: 100%;
  }

  .form-inline .form-group {
    margin-right: 0;
  }
}

// Upload photo
.icon-camera {
  position: relative;
  right: 20px;
  top: 30px;
  padding: 8px;
  border-radius: 50px;
  cursor: pointer;
}

// PDF
.rpv-core__inner-page {
  background-color: transparent !important;
}