.profile-img > img {
    width: 2.5rem;
}

.contacts-table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid var(--au-hr);
}

.verified {
    color: var(--au-green);
}

.not-verified {
    color: var(--au-red);
}

.contacts-pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px !important;
}