.overlay-preview img {
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    object-fit:fill;
    z-index: 2;
}

.confirm-preview {
    position: fixed !important;
    bottom: 10%;
    left: 10%;
    right: 10%;
    text-align: center;
    z-index: 3;
    background-color: #1f2d3d;
    color: white;
    padding: 12px;
    border-radius: 1rem;
}