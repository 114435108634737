.container-sp {
    background-color: #343a40;
    min-height: 100vh;
    height: max-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
}

.footer-sp {
    color: white;
    margin-top: 1rem;
}