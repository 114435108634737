.document-side {
    position: fixed !important;
    background-color: transparent !important;
    color: #ffc107 !important;
    font-weight: 600;
    font-size: 20px;
    border-radius: 25px !important;
    border: none !important;
    top: 4%;
    left: 10%;
    right: 10%;
    text-align: center;
    z-index: 3;
    padding: 0 !important;
}

.overlay-document img {
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    object-fit:fill;
}

.overlay-document img {
    z-index:2
}

.take-picture {
    position: fixed !important;
    bottom: 10%;
    left: 10%;
    right: 10%;
    z-index: 3;
    display: flex;
    justify-content: center;
}