:root {
    --au-text-color: #141414;
    --au-hr: #dee2e6;

    --au-red: #e53935;
    --au-orange: #F1581B;
    --au-yellow: #ffc107;
    --au-green: #00bc8c;
    --au-blue: #3498db;
    --au-grey: #6c757d;
    --au-grey2: #ced4da;
    --au-black: #000;
    --au-brown: #795548;
}

.au-red {
    color: var(--au-red);
}