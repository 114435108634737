.mensagem-orientacao {
    position: fixed !important;
    background-color: #ffc107 !important;
    color: white !important;
    border-radius: 25px !important;
    border: none !important;
    bottom: 10%;
    left: 10%;
    right: 10%;
    text-align: center;
    z-index: 3;
}
.overlay-selfie img {
    width:100%;
    height:110%;
    position:fixed;
    top:0;
    left:0;
    object-fit:cover;
}

.overlay-selfie img {
    z-index:2
}