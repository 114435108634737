label {
    font-size: 16px;
}

.title-sm {
    font-weight: 700;
    font-size: 16px;
}

.title-md {
    font-weight: 700;
    font-size: 18px;
}

.description-md {
    font-size: 16px;
}

.description-sm-center {
    font-size: 12px;
    text-align: center;
}

.description-sm {
    font-size: 12px;
}