.profilepic {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.profilepic:hover .profilepic__content {
    opacity: 1;
}

.profilepic:hover .profilepic__image {
    opacity: .5;
}

.profilepic__image {
    object-fit: cover;
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.profilepic__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--au-black);
    opacity: 0;
    transition: opacity .2s ease-in-out;
}

.profilepic__icon {
    color: var(--au-black);
    padding-bottom: 8px;
}

.profilepic__text {
    text-transform: uppercase;
    font-size: 12px;
    width: 50%;
    text-align: center;
}